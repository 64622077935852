import React from 'react';
import './Banner.css';

import {
    banner1,
    banner2,
    banner3,
    banner4,
 } from '../photos/notBeadwork';

const pics = [
    banner3,
    banner4,
    banner1,
];

const delay = 4000;

function Banner() {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === pics.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className=" bslideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {pics.map((image, index) => (
          <img
            className="bslide"
            key={index}
            src={image}
          />
        ))}
      </div>
    </div>
  )}

export default Banner;