import React, { useEffect, useState } from "react";
import { arrowBackToShop } from "../photos/notBeadwork";
import { Link, useLocation } from "react-router-dom";
import { functions} from "../firebase";
import { httpsCallable } from "firebase/functions";

const CartSuccess = () => {

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const clientS = params.get("payment_intent_client_secret");
  const [loading , setLoading] = useState(true);

  useEffect(() => {
    const updateUserCart = async () => {
      try {
        console.log("::",clientS);
        const updateCart = httpsCallable(functions, "processCartItems");
        const response = await updateCart({
          clientS: clientS,
        });
        console.log("Response from Cloud Function:", response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching the client secret", error.message);
      }
    };

    updateUserCart();
  }, [clientS]);
  // Render loading indicator if still loading

  return (
    <div className="md:mt-28 md:py-20 mt-14 py-16 bg-[#F2EFEB] text-[#8d6464] blackest text-center">
      {loading ? (
        <div className="pt-6">
          <h3 className=" text-md font-mono ">Loading...</h3>
          <p className=" text-sm text-gray-500">do not refresh page</p>
        </div>
      ) : (
        <>
          <h1 className="max-w-[400px] mx-auto">
            YAKOOKAY! <br />
            THANK YOU FOR YOUR PURCHASE
          </h1>
          <h4>
            You will receive an email receipt with a tracking number shortly
          </h4>
          {/* Pass the userID to the CartParser component */}
          <Link
            to="/Shop"
            className="text-base col-span-2 text-center no-underline bg-[#dccada] text-[#545354] py-3 mt-6 rounded-sm duration-300"
          >
            <img
              src={arrowBackToShop}
              alt="about the artist"
              className="w-[12rem] mx-auto"
            />
          </Link>
        </>
      )}
    </div>
  );
};

export default CartSuccess;
