import React, { useState, useEffect } from "react";
import "./CheckoutCSS.css";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { httpsCallable } from "firebase/functions";
import { functions, auth, db } from "../firebase";
import { useLocation } from "react-router-dom";

import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
} from "firebase/firestore";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function ProceedToCheckout() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const amount = params.get("amount") * 1;
  const prodID = params.get("prodID");
  const userID = params.get("userID");

  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(true);
  console.log(typeof parseFloat(amount));
  console.log("prodID: ", prodID);
  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const paymentIntentFn = httpsCallable(functions, "payIntent");
        console.log("Other prodID: ", prodID);
        console.log("UserID", userID);
        const response = await paymentIntentFn({
          amount: amount,
          prodID: prodID,
          userID: userID,
        });
        setClientSecret(response.data.clientSecret);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching the client secret", error);
      }
    };

    fetchClientSecret();
  }, [amount, prodID, userID]);

  const appearance = {
    theme: "flat",
    variables: {
      colorPrimaryText: "#202124",
      colorBackground: "#dccada",
    },
  };
  const options = {
    clientSecret,
    appearance,
  };


  return (
    <div className="StripeApp mt-14 md:mt-32 md:mb-4 shadow-sm pt-2 md:py-8 text-center">
      <div className="payment-container">
        {loading ? (
          <div className="pt-6">
            <h3 className=" text-md font-mono ">Loading...</h3>
            <p className=" text-sm text-gray-500">do not refresh page</p>
          
          </div>
          
        ) : (
          <>
            {console.log("clientS: ", clientSecret)}
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
            )}
          </>
        )}
        <p className="payment-amount text-md font-mono font-light w-full">
          Total: ${amount}
        </p>
      </div>
    </div>
  );
}
