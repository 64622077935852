import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
import { Link } from 'react-router-dom';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css/free-mode';
import 'bootstrap/dist/css/bootstrap.min.css';
import './products.css';

import { 
  insta6purpflat,
  pastprojects7,
  pastprojects12,
  pastprojects11,
  pastprojects9,
  mushrooms,
  pastprojects10,
  purplegreenpretty,
  morepinkhearts, 
  purpletheme,
  bigpinkhearts, flowershells, shellflowerassorted,
  whitepastprojects1,
  pastprojects2,
  pastprojects3,
  whitepastprojects3,
} from "../photos";



const HomePPCarousel = () => {

  return (
    <div>
      <div className='w-full pl-2 '>
          <Swiper
            FreeMode={true}
            grabCursor={true}
            navigation={true}
            modules={[FreeMode, Navigation ]}
            className="mySwiper insta-sq m-0 "
            style={{'--swiper-navigation-size': '20px'}}
            breakpoints={{
              0: {
                slidesPerView: 2.25,
                spaceBetween: 5,
              },
              480: {
                slidesPerView: 2.25,
                spaceBetween: 5,

              },
              640: {
                slidesPerView: 4.2,
                spaceBetween: 10,
              },
              760: {
                slidesPerView: 4.2,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 4.2,
                spaceBetween: 10,
              },
              1200: {
                slidesPerView: 4.2,
                spaceBetween: 10,
              }
            }}
          >
            <SwiperSlide>
              <img 
                  className=' h-[42vw] w-[42vw]  md:h-[23vw] md:w-[23vw]'
                  src={flowershells}
                  alt="Beaded flower dentallium earrings"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img 
                  className='h-[42vw] w-[42vw]   md:h-[23vw] md:w-[23vw]'
                  src={shellflowerassorted}
                  alt="Chickasaw Dangle earrings"
              />
            </SwiperSlide>
            <SwiperSlide >
              <img 
                  className=' h-[42vw] w-[42vw]  md:h-[23vw] md:w-[23vw]'
                  src={whitepastprojects1}
                  alt="indigenous made dentallium earrings"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img 
                  className='h-[42vw] w-[42vw]  md:h-[23vw] md:w-[23vw]'
                  src={purplegreenpretty}
                  alt="Beaded flatwork jewelry and bolo ties"
              />
            </SwiperSlide>
                
            <SwiperSlide>
              <img 
                className='h-[42vw] w-[42vw] md:h-[23vw] md:w-[23vw]'
                src={whitepastprojects3}
                alt="Native American Beadwork"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img 
                className='h-[42vw] w-[42vw] md:h-[23vw] md:w-[23vw]'
                src={pastprojects2}
                alt="Native American Beadwork"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img 
                className='h-[42vw] w-[42vw] md:h-[23vw] md:w-[23vw]'
                src={purpletheme}
                alt="Native American Beadwork"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img 
                  className='h-[42vw] w-[42vw] md:h-[23vw] md:w-[23vw]'
                  src={pastprojects3}
                  alt="Native made not native inspired"
              />
            </SwiperSlide>
          </Swiper>
      </div>
    </div>
  )
}

export default HomePPCarousel;