import React from 'react';
import { 
  kat0,
  kat1,
  kat2,
  kat3,
  kat4,
  kat5,
 } from '../photos';


 
 import { 
  kat6,
  ellissaKat,
  ellissahome,
  katgalagahand,
  katgalagaLoom,
  katheartproj,
  littlearrow,
 } from '../photos/notBeadwork';
import '../components/Subscribe.css';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'bootstrap/dist/css/bootstrap.min.css';

const AboutTheArtist = () => {
  
  return (
    <div className='md:mt-28 md:py-12 mt-14 pt-8  bg-[#f2eaeb] text-[#585656] quattrocento'>
      <section class=" mx-auto max-w-6xl">
        <div>
          <div className=' text-center '> 
            <h1 className='text-[#8d6464] blackest'>ABOUT THE ARTIST</h1>
          </div>

          <div class="grid gap-4 px-4 py-4 sm:grid-cols-2  sm:gap-6">
            <div class="w-full sm:order-2 items-center">
              <img src={ellissahome} alt='about the artist' className="h-w w-full" />   
            </div>
            <div class="w-full h-w self-center px-0  sm:order-3 md:justify-center">
              <h1 className='text-[#8d6464] blackest '>Ellissa K Thompson</h1>
              <p className='leading-8'> 
              As a young woman growing up in the vibrant Native American community in the San Francisco Bay Area, Ellissa always admired the beadwork of the powwow dancers and aunties around her. After getting over the initial intimidation, she finally picked up a needle and thread at 22 years old and worked until the sun came up! When COVID-19 caused her job to close their physical doors and go online, she was struggling to find a job and decided to turn her hobby into a way to support herself. She has found immense support and education through the amazing beading circle at Intertribal Friendship House Oakland, the Bay Area Native Community, and her community back in Chickasaw Country. 
              </p>
            </div>
            <div id="ataimg" class="w-full items-center sm:order-5">
              <img src={ellissaKat} alt='about the artist' className="h-w w-full" />   
            </div>
            <div id="atatext" class="w-full self-center px-0  h-w sm:order-4">
              <h1 className='text-[#8d6464] blackest'>History of Ihoo Taloowa</h1>
              <p className='leading-8'>
                At the core, all the artists that contribute to Ihoo Taloowa believe that beadwork is a way to share our cultures with the world. We hope you feel the love in our work and share our story when people say “Oh my goodness! I love your earrings!” 
              </p>
            </div>
          </div>

          {/**  FEATURED ARTISTS  */}
          <div class=" text-center mx-auto max-w-6xl ">
            <h1 className='text-[#8d6464] blackest py-8'>FEATURED ARTISTS</h1> 

            {/* KAT HERMAN-WILLIAMS */}
            <div className="grid gap-4 px-3 sm:grid-cols-2 sm:pb-8 md:pb-8 lg:pb-8 sm:gap-6">
              <div clasNames="w-full sm:order-2 items-center">
                <img src={kat6} alt='Kat Herman-Williams, artist, beader, writer, and web developer' className="h-w w-full" />   
              </div>
              <div class="w-full h-w self-center text-left  sm:order-3 md:justify-center">
                <h1 className='text-[#8d6464] blackest'>Kat Herman-Williams</h1>
                <p className='leading-8'> 
                  Kat is a 26 year-old member of the Choctaw Tribe with Kiowa, Lakota, and Caddo heritage living in Hemet, California. 
                  Originally from Boulder, Colorado, she moved to the west coast to attend the University of Redlands where she received
                  her Bachelor's Degree in Computer Science. She is an artist, beader, web developer, and creator of this website! 
                  For web development inquiries, please contact:katherinehw@icloud.com
                </p>
              </div>
            </div>

            <div className='katgrid container-fluid grid grid-cols-3 gap-1 py-2 relative justify-content-center'>
              <img src={kat1} alt='about the artist' className="max-h-[32vw] lg:max-h-[25vw]" />
              <img src={kat2} alt='about the artist' className=" max-h-[32vw] lg:max-h-[25vw]" />
              <img src={katheartproj} alt='about the artist' className=" max-h-[32vw] lg:max-h-[25vw]" />
              <img src={kat4} alt='about the artist' className="max-h-[32vw] lg:max-h-[25vw]" />
              <img src={kat0} alt='about the artist' className="max-h-[32vw] lg:max-h-[25vw]" />
              <img src={katgalagaLoom} alt='about the artist' className="max-h-[32vw] lg:max-h-[25vw]" />
              <img src={kat3} alt='about the artist' className="max-h-[32vw] lg:max-h-[25vw]" />
              <img src={kat5} alt='about the artist' className="max-h-[32vw] lg:max-h-[25vw]" />
              <img src={katgalagahand} alt='about the artist' className=" max-h-[32vw] lg:max-h-[25vw]" />
            </div>

            <div className="px-2 py-8 md:py-16">
              <div class=" h-w self-center text-left  sm:order-3 md:justify-center">
                <h1 className='text-[#8d6464] blackest'>Nevaeh Treviño</h1>
                <p className='leading-8'> 
                    Nevaeh is a 22 year old multi-media artist and community worker with Navajo and Eastern Shoshone roots.
                    Born and raised in Oakland, California, she can often be found hosting various community events when she’s not busy beading away with her friends, elders, 
                    and family. 
                </p>
                <a href="/Shop" className="text-[#8d6464] blackest  no-underline">
                  Shop Neveah's beadwork
                  <img src={littlearrow} alt='about the artist' className="w-[175px]" />
                </a>
              </div>
            </div>
          </div>
        </div>

      </section>
    </div>
  );
};

export default AboutTheArtist;