import React from 'react';
import './Slideshow.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import {
    pastprojects9,
    insta5bluetable,
    realPinkButterflies,
    insta3assorted,
} from '../photos';

import {
    diverseTableShot,
} from '../photos/notBeadwork';

const pics = [
    realPinkButterflies,
    insta5bluetable,
    insta3assorted,
    pastprojects9,
];

const delay = 5000;

function Slideshow() {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === pics.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );
    return () => {
      resetTimeout();
    };
  }, [index]);

  return (

    <div>
      <Swiper
          spaceBetween={5}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
      >
      <SwiperSlide>
        <img src={realPinkButterflies} className='slide' alt="native made jewelry"/>
      </SwiperSlide>
      <SwiperSlide>
        <img src={diverseTableShot} className='slide' alt="native made jewelry"/>
      </SwiperSlide>
      <SwiperSlide>
        <img src={insta5bluetable} className='slide' alt="native made jewelry"/>
      </SwiperSlide>
      <SwiperSlide>
        <img src={insta3assorted} className='slide' alt="native made jewelry"/>
      </SwiperSlide>
      <SwiperSlide>
        <img src={pastprojects9} className='slide' alt="native made jewelry"/>
      </SwiperSlide>
    </Swiper>
  </div>
)}


export default Slideshow;