import React from 'react'
import { BsEnvelope } from "react-icons/bs";
import { 
    mailingListwArrows,
    littlearrow,
    bigscreensubscribe,
} from "../photos/notBeadwork";
import InstaCarousel from "./InstaCarousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Subscribe.css";
import { db } from '../firebase';
import { setDoc, doc } from 'firebase/firestore';
import { useState, useEffect, useRef } from 'react';




const Bottom = () => {

    const[email, setEmail] = useState("");
    const[toggle, setToggle] = useState(false);
    const timerId = useRef(null);

    useEffect(() => {

        if(toggle) {

            timerId.current = setTimeout(() => {

                setToggle(false);

            }, 5000);

        }

        return () => {

            clearTimeout(timerId.current);

        }

    }, [toggle]);

    async function sendData() {

        if(email == ""){/*Put Alert Here FIELD MUST BE FILLED IN*/}

        else{ {/** SEND EMAILS TO DATABASE AND TO ELLISSA ADMIN PAGE SUBSCRIBERS LIST */}

            var name = email.replace(/@.*$/,"");

            setToggle(true);

            const docRef = await setDoc(doc(db, "NewsLetter", name), {

                email : email

            });

            setEmail("");

        }    


    }

  return (
    <div>
            <div className='subscribe text-[#664A2C]'>
                <div className="heart text-center">
                    <img className='hearttemps ' src={mailingListwArrows} alt='Subscribe! Chickasaw made, Bay raised'/>
                    <img className='hearttemps2' src={bigscreensubscribe} alt='Subscribe! Chickasaw made, Bay raised'/>
                    
                    <div class="centered-text">
                        {toggle && (
                            <div className='success-msg mx-auto sm:pt-12 sm:pr-0'>
                                
                                    <h1 className=''>YAKOOKAY! </h1>
                                    <p className=' sm:pt-8 pt-4 sm:pr-0'>Thank you for subscribing to Ihoo Taloowa Beads and entering our website launch raffle. </p>
                                
                            </div>
                        )}
                        {!toggle && (
                            

                            <div>
                                <h2 className='connectwithus blackest'>JOIN THE MAILING LIST!</h2>
                                <div className="newsletter-form blackest text-xl">
                                    <br></br>E-MAIL:
                                    <input className="email-input bg-none text-[#664A2C] pl-2" type="email"  name="email" id="user" required onChange={
                                        (e) => setEmail(e.target.value)
                                    } />
                                    <button onClick={sendData} class="email-submit" title="subscribe" name="submit"><BsEnvelope/></button>
                                </div>
                             </div>    

                        )}


             
                    </div>
                </div>
                
            </div>
            
           



    {/* SUBSCRIBE HEART END */}




    {/* FIND US ON INSTAGRAM STRIP START */}


    <div className='text-center bg-[#F2EFEB] text-[#664A2C] blackest pb-3 md:pb-0 pt-3'>
        <div className='max-w-[300px] mx-auto flex-wrap'>
            <h3 className='mb-0'>
                FOLLOW <br></br>
                <a href='https://www.instagram.com/ihootaloowa/' alt="Native American beadwork. Chickasaw made, bay raised." class=" hover:text-[#c7adad] text-[#9d7c7c] no-underline px-1">
                    @IHOOTALOOWA 
                </a>
                ON INSTAGRAM
            </h3>
            <img className='max-w-[160px] mx-auto' src={littlearrow} alt='Native American beadwork and jewelry'/>
                    
        </div>

            <InstaCarousel />
        
        
    </div>

    {/* FIND US ON INSTAGRAM STRIP END*/}

        

  
    </div>

    
        
  )
}

export default Bottom;