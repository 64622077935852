import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper/modules";


import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';

import 'bootstrap/dist/css/bootstrap.min.css';
import './products.css';

//import images
import { 

  insta2pinktable, 
  comeSeeUs1, 
  comeSeeUs2, 
  comeSeeUs3,
  insta5bluetable, 

} from "../photos";





const HomeWTFM = () => {
  return (
    <div className='relative px-2 w-full lg:max-w-[85vw] my-0 mx-auto'>
        
        <Swiper
          FreeMode={true}
          grabCursor={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[FreeMode, Navigation]}
          className="mySwiper"
          style={{'--swiper-navigation-size': '20px'}}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 5,
              
            },
            480: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            760: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 16,
            }
          }}
        >
          
        <SwiperSlide className='md:order-1'>
            <img 
              className='w-[95vw] mx-auto h-[95vw] sm:w-[48vw] sm:h-[48vw]  
                        lg:w-[30vw] lg:h-[30vw]' 
              src={comeSeeUs1}
              alt="Ihoo Taloowa indigenous made jewelry vending"
          />
        </SwiperSlide>
        <SwiperSlide className='md:order-3'>
          <img 
            className='w-[95vw] mx-auto h-[95vw] sm:w-[48vw] sm:h-[48vw]  
            lg:w-[30vw] lg:h-[30vw]'
            src={comeSeeUs3}
            alt="In person vending event table with assorted hand made jewelry"
          />
        </SwiperSlide>
        <SwiperSlide className='md:order-4'>
          <img 
            className='w-[95vw] mx-auto h-[95vw] sm:w-[48vw] sm:h-[48vw]  
            lg:w-[30vw] lg:h-[30vw]'
            src={comeSeeUs2}
            alt="Vending table with various Native American fringe earrings "
          />
        </SwiperSlide>
        
       
        
          
          
        </Swiper>
        
        
        
      </div>
        
        

  )
}

export default HomeWTFM;