import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import './pastprojects.css';


let imageList;


try {

  const images = require.context('../photos/Beadwork', true);
  imageList = images.keys().map(image => images(image));
  
} catch (error) {
    console.log("Error", error);
}


const PastProjects = ({projects}) => {







  

  return(
    
    <div class="md:mt-28 md:py-12 mt-14 py-8  bg-[#f2eaeb] blackest text-center">
      <h1 className='text-[#8d6464] pb-6'>PAST PROJECTS</h1>

     

      <div className="threeCol">    
        {imageList.map((image, index) => (
          <div className='pics' key={index}  alt='Native made, not Native inspired'> 
            
            <img className="w-[33vw]"key={index} src={image} alt={`images-${index}`} />
            
          </div>
          
        ))}
      
        
      </div>

    </div>
  )

}
    



export default PastProjects;