import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { db } from './firebase';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store} app={db}>
        <PersistGate loading={"loading"} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
);  
    

