

import { useState, useEffect } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

export const ProductsData = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const retrieveStripeProducts = httpsCallable(
          functions,
          "retrieveStripeProducts"
        );
        const response = await retrieveStripeProducts();

        console.log("Response Data", response.data);
        const sortedProducts = response.data.sort((a, b) => {
          // If "sold" metadata is false, prioritize it over true
          console.log("here as well", a);//CONSOLE LOG DELETE????????????????????????????
          if (a.metadata.sold === "false" && b.metadata.sold === "true") {
            return -1; // a should come before b
          } else if (a.metadata.sold === "true" && b.metadata.sold === "false") {
            return 1; // b should come before a
          } else {
            return 0; // leave the order unchanged
          }
        });

        console.log("Here");
        console.log("Response Data", response.data);


        setProducts(sortedProducts);
        setLoading(false);
        console.log("products: ", sortedProducts);
        
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  return { products, loading };
};
