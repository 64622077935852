import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper/modules";
import { Link } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'bootstrap/dist/css/bootstrap.min.css';

//import images
import { 
  insta1succulent, 

  insta4rainbow, 

  kat5,
  pastprojects7,
  pastprojects12,
  pastprojects6,
  mushrooms,
  pinkgreen,
  nature3,
  slide3,
  littlepurple,
  kat0,
  nature1,
  instaleaves, 



} from "../photos/";



const InstaCarousel = () => {
  return (
    <div className='container-fluid px-1 py-2 justify-content-center'>
        <Swiper
          FreeMode={true}
          grabCursor={true}
          navigation={true}
          modules={[FreeMode, Navigation]}
          className="mySwiper"
          style={{'--swiper-navigation-size': '20px'}}
          breakpoints={{
            0: {
              slidesPerView: 4,
              spaceBetween: 4,
            },
            480: {
              slidesPerView: 4,
              spaceBetween: 4,
            },
            640: {
              slidesPerView: 5,
              spaceBetween: 4,
            },
            760: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 8,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 8,
              spaceBetween: 10,
            }
          }}


          /*
          slidesPerView={3}
          spaceBetween={30}
          */

        >
          <SwiperSlide className="">
            <Link to="https://www.instagram.com/ihootaloowa/">
              <img 
                  className='h-[24vw] w-[24vw]  lg:h-[12vw]                           '
                  src={insta1succulent}
                  alt="instagram 1"
              />
  </Link>
          </SwiperSlide>
          
          <SwiperSlide className="">
          <Link to="https://www.instagram.com/ihootaloowa/">
            <img 
                className='h-[24vw] w-[24vw]  lg:h-[12vw]                          '
                src={insta4rainbow}
                alt="instagram 2"
            />
            </Link>

        </SwiperSlide>
        <SwiperSlide className="">
        <Link to="https://www.instagram.com/ihootaloowa/">
            <img 
                  className='h-[24vw] w-[24vw]  lg:h-[12vw]                          '
                  src={mushrooms}
                  alt="instagram 5"
              />
            </Link>
        </SwiperSlide>
        <SwiperSlide className="">
        <Link to="https://www.instagram.com/ihootaloowa/">
          <img 
                className='h-[24vw] w-[24vw]  lg:h-[12vw]                          '
                src={pinkgreen}
                alt="instagram 3"
            />
            </Link>
        </SwiperSlide>
        
        
    
        <SwiperSlide className="">
          <Link to="https://www.instagram.com/ihootaloowa/">
            <img 

                className='h-[24vw] w-[24vw]  lg:h-[12vw]                          '
                src={slide3}
                alt="instagram 6"
              />
          </Link>
        </SwiperSlide>
        <SwiperSlide className="">
          <Link to="https://www.instagram.com/ihootaloowa/">
            <img 

                className='h-[24vw] w-[24vw]  lg:h-[12vw]                          '
                src={kat0}
                alt="instagram 6"
              />
          </Link>
        </SwiperSlide>
        <SwiperSlide className="">
          <Link to="https://www.instagram.com/ihootaloowa/">
            <img 

                className='h-[24vw] w-[24vw]  lg:h-[12vw]                          '
                src={instaleaves}
                alt="instagram 6"
              />
          </Link>
        </SwiperSlide>
        <SwiperSlide className="">
          <Link to="https://www.instagram.com/ihootaloowa/">
          <img 
                className='h-[24vw] w-[24vw]  lg:h-[12vw]                          '
                src={nature1}
                alt="instagram 4"
            />
            </Link>
        </SwiperSlide>
        

      
      </Swiper>
       
        
    </div>
  )
}

export default InstaCarousel;