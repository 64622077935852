import React from 'react';


import './pastprojects.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import StaticHtml from '../temppp';


const PrivacyPolicy = () => {

  

  return (

    <div class=" quattrocento ">
      <div class="px-4 my-20 lg:mt-16 mx-auto max-w-6xl lg:py-12">
       

            <StaticHtml/>
      
      </div>
    </div>
    
        
   
    
  

  );
   

};

export default PrivacyPolicy;