import React from 'react';
import ProductsCard from '../components/ProductsCard';
import { ProductsData } from '../api/Api';

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import '../components/productsCard.css';

const Shop = () => {
    
  const { products, loading } = ProductsData();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='md:mt-28 md:py-12 mt-14 py-8  text-center bg-white text-[#8d6464] blackest'>
      
      <h1>SHOP</h1>

      <div className=' text-[#90788d] mx-auto left max-w-[900px] pt-3 px-1'>    
      <ToastContainer
            position="top-left"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />      
          <div className="   py-2 grid grid-cols-2  sm:grid-cols-3 md:gap-6 gap-2">
            
              {
              products.map((item)=>(
                      <ProductsCard key={item._id} product={item} className="max-h-[400px] "/>
              ))}
          </div>
        </div>
    </div>
  )
}

export default Shop;