import  React from "react";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Carousel from "./components/Carousel";
import Cart from "./pages/Cart";
import AboutTheArtist from "./pages/AboutTheArtist";
import WhereToFindMe from "./pages/WhereToFindMe";
import PastProjects from "./pages/PastProjects";
import FAQ from "./pages/FAQ";
import EllissaAdmin from "./pages/EllissaAdmin";
import InstaCarousel from "./components/InstaCarousel";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Shop from "./pages/Shop";
import CartSuccess from "./pages/CartSuccess";
import ProceedToCheckout from "./stripecheckout/ProceedToCheckout";
import TermsandCond from "./pages/TermsandCond";
import CookiePolicy from "./pages/CookiePolicy";
import UpcomingEvent from "./components/UpcomingEvent";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import Product from "./components/Product";
import Login from "./pages/Login";
import Bottom from "./components/Bottom";
import { Helmet } from "react-helmet";
import Auth from "./auth/Auth";


/**import './App.css';*/


import 'swiper/css';
import 'swiper/css/free-mode';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./components/Subscribe.css";

//import images



const Layout=()=>{

  return(
    <div>

      <Helmet>
          <meta
          name="description"
          content="Authentic native american beaded jewelry handmade by Ellissa Thompson. Chickasaw made, Bay raised."
        />
      </Helmet>

      {/** 
      <div className="header ">
        <img className='logo' src={logoDark} alt="Ihoo Taloowa" />
      </div>

      <div className="bg-[#f8eff4] mt-0 py-3">
        <div className='body p-6 mt-0'>
            <h2>Chokma!</h2>
            <h2>Hello!</h2>
            <h4 className="max-w-[475px] mx-auto">Website in progress,<br></br>
              follow us on
              <a class="no-underline bg-white text-[#6e5a67] quattrocento" href="https://www.instagram.com/ihootaloowa/" alt="Instagram"> instagram </a>
            and sign up for emails to stay updated!</h4>
            <h2>Chipisila'cho!</h2>
            <h2>See you later!</h2>
            <img className="mx-auto my-2 h-12 w-h" src={githubRed} alt="Ihoo Taloowa" />   
        </div> 
      </div>

      <Bottom />

      <div className='instaSection bg-[#fdf4f6]'>



          <h2 className='bg-[#fdf4f6] py-3 marginAdj'>Visit our 
            <a  class="underline bg-[#fdf4f6] text-[#c8aab1] hover:text-[#c8aab1] px-2" href="https://www.instagram.com/ihootaloowa/" alt="Instagram">instagram</a>
           for more</h2>


      <InstaSwiper />
  </div> 

    

      <Header />*/}
      <Navbar />
      <ScrollRestoration />
      <Outlet />
      <Bottom />
      <Footer />

    </div>
  )
};

const router = createBrowserRouter([
  {
    path:"/",
    element:<Layout />,
    children:[
      {
        path:"/",
        element: <Home />,
      },
      {
        path:"/product/:id",
        element:<Product/>,
      },
      {
        path:"/cart",
        element: <Cart />,
      },
      {
        path:"/login",
        element:<Login />,
      },
      {
        path:"/AboutTheArtist",
        element:<AboutTheArtist />,
      },
      {
        path:"/WhereToFindMe",
        element:<WhereToFindMe />,
      },
      {
        path:"/PastProjects",
        element:<PastProjects />,
      },
      {
        path:"/FAQ",
        element:<FAQ />,
      },
      {
        path:"/EllissaAdmin",
        element:<EllissaAdmin />,
      },
      {
        path:"/Navbar",
        element:<Navbar/>,
      },
      {
        path:"/Carousel",
        element:<Carousel />,
      },
      {
        path:"/InstaCarousel",
        element:<InstaCarousel />,
      },
      {
        path:"/PrivacyPolicy",
        element:<PrivacyPolicy />,
      },
      {
        path:"/TermsandCond",
        element:<TermsandCond />,
      },
      {
        path:"/CookiePolicy",
        element:<CookiePolicy />,
      },
      {
        path:"/CartSuccess",
        element:<CartSuccess />,
      },
      {
        path:"/ProceedToCheckout",
        element:<ProceedToCheckout/>,
      },
    
      {
        path:"/Shop",
        element:<Shop />,
      },

      {
        path:"/UpcomingEvent",
        element:<UpcomingEvent />,
      },
     

    ]
  }
])


function App() {

  const userID = Auth();

 

  return (
    <div >
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;
