import React from "react";
import Collapsible from "react-collapsible";

import "./ellissaadmin.css";

const EllissaAdmin = () => {
  return (
    <div className=" font-serif md:mt-28 mt-14 ">

          <div className="col-span-5 bg-[#eee7ef] p-4">
            <h1 className="text-gray-600 text-center font-serif font-titlefont">
              Welcome, Ellissa
            </h1>
          </div>

        
          <div className=" flex-grid md:grid gap-2 grid-cols-5 text-center sm:gap-6">


            {/* ORDERS 

            <div className="text-left col-span-3 bg-[#efe7ed] p-2 md:order-2">
              <h1 className="text-gray-600 text-center">Orders</h1>
              <hr className="w-full mx-auto"></hr>
              {/** PENDING ORDERS 
              <div>
                <h4>Pending Orders</h4>
                <hr className="w-full mx-auto"></hr>
                <h2 className="text-[#a0a0a0]">currently no pending orders</h2>
              </div>
              {/** SHIPPED ORDERS 
              <div>
                <h4>Shipped Orders</h4>
                <hr className="w-full mx-auto"></hr>
                <ul className="pl-0">
                  <li>
                    <Collapsible className="bg-[#fff8f8]  " trigger="+ Order #1 3-22 12:36pm $94.95">
                      <form action="#" className="py-4">
                            <div class="grid bg-[#ffffff] grid-cols-2 gap-2 max-w-[700px] p-3">
                              <div class="col-span-2 lg:w-full">
                                <label
                                  for="email"
                                  class="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Email
                                </label>
                                <input
                                  type="email"
                                  name="email"
                                  id="email"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm  block w-full p-2.5 "
                                  placeholder="jalsanxh@gmail.com"
                                />
                              </div>

                              <div class="col-span-2 lg:w-full">
                                <label
                                  for="name"
                                  class="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Name
                                </label>
                                <input
                                  type="text"
                                  name="name"
                                  id="name"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm block w-full p-2.5"
                                  placeholder="Jalixa Sanchez"
                                />
                              </div>
                              <div class="w-full">
                                <label
                                  for="address"
                                  class="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Address
                                </label>
                                <input
                                  type="text"
                                  name="address"
                                  id="address"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm block w-full p-2.5"
                                  placeholder="17000 Le Claire Ave"
                                />
                              </div>
                              <div class="w-full">
                                <label
                                  for="city"
                                  class="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  City
                                </label>
                                <input
                                  type="text"
                                  name="city"
                                  id="city"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm block w-full p-2.5"
                                  placeholder="Tinley Park"
                                />
                              </div>
                              <div class="w-full">
                                <label
                                  for="state"
                                  class="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  State
                                </label>
                                <input
                                  type="text"
                                  name="state"
                                  id="state"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm block w-full p-2.5"
                                  placeholder="IL"
                                />
                              </div>
                      
                              <div class="w-full">
                                <label
                                  for="zipcode"
                                  class="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Zip Code
                                </label>
                                <input
                                  type="text"
                                  name="zipcode"
                                  id="zipcode"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm block w-full p-2.5"
                                  placeholder="60477"
                                />
                              </div>
                              <div class="col-span-2">
                                <label
                                  for="instruction"
                                  class="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Products
                                </label>
                                <input
                                  id="instruction"
                                  name="instruction"
                                  class=" text-sm text-gray-900 bg-gray-50 rounded-sm border border-gray-300 block w-full p-2.5"
                                  placeholder="$90 Purple Abalone & Dentalium"
                                ></input>
                              </div>
                              <div class="col-span-2">
                                <label
                                  for="total"
                                  class="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Total
                                </label>
                                <input
                                  id="total"
                                  name="total"
                                  class=" text-sm text-gray-900 bg-gray-50 rounded-sm border border-gray-300 block w-full p-2.5"
                                  placeholder="$90"
                                ></input>
                              </div>
                              <div class="w-full">
                                <label
                                  for="databaseID"
                                  class="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Database ID
                                </label>
                                <input
                                  type="text"
                                  name="databaseID"
                                  id="databaseID"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm block w-full p-2.5"
                                  placeholder="fmWEKRHk3oUSkZCLPBYMBRSHCwl1"
                                />
                              </div>
                              <div class="w-full">
                                <label
                                  for="tracking"
                                  class="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Tracking #
                                </label>
                                <input
                                  type="text"
                                  name="tracking"
                                  id="tracking"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm block w-full p-2.5"
                                  placeholder="#112500214089792203"
                                />
                              </div>

                        
                            </div>
                          </form>


                      </Collapsible>
                    </li>
                    <li>
                      <Collapsible className="border-[#b1b1b1] border-y-[1px] " trigger="+ Order #2 3-22 8:02pm $204.95">
                      <form action="#" className="py-4">
                            <div class="grid bg-[#ffffff] grid-cols-2 gap-2 max-w-[700px] p-3">
                              <div class="col-span-2 lg:w-full">
                                <label
                                  for="email"
                                  class="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Email
                                </label>
                                <input
                                  type="email"
                                  name="email"
                                  id="email"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm  block w-full p-2.5 "
                                  placeholder="monse_jaramillo30@yahoo.com"
                                />
                              </div>

                              <div class="col-span-2 lg:w-full">
                                <label
                                  for="name"
                                  class="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Name
                                </label>
                                <input
                                  type="text"
                                  name="name"
                                  id="name"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm block w-full p-2.5"
                                  placeholder="monserrat jaramillo"
                                />
                              </div>
                              <div class="w-full">
                                <label
                                  for="address"
                                  class="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Address
                                </label>
                                <input
                                  type="text"
                                  name="address"
                                  id="address"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm block w-full p-2.5"
                                  placeholder="222 glen drive"
                                />
                              </div>
                              <div class="w-full">
                                <label
                                  for="city"
                                  class="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  City
                                </label>
                                <input
                                  type="text"
                                  name="city"
                                  id="city"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm block w-full p-2.5"
                                  placeholder="Sausalito"
                                />
                              </div>
                              <div class="w-full">
                                <label
                                  for="state"
                                  class="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  State
                                </label>
                                <input
                                  type="text"
                                  name="state"
                                  id="state"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm block w-full p-2.5"
                                  placeholder="CA"
                                />
                              </div>
                      
                              <div class="w-full">
                                <label
                                  for="zipcode"
                                  class="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Zip Code
                                </label>
                                <input
                                  type="text"
                                  name="zipcode"
                                  id="zipcode"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm block w-full p-2.5"
                                  placeholder="94965"
                                />
                              </div>
                              <div class="col-span-2">
                                <label
                                  for="instruction"
                                  class="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Products
                                </label>
                                <input
                                  id="instruction"
                                  name="instruction"
                                  class=" text-sm text-gray-900 bg-gray-50 my-2 rounded-sm border border-gray-300 block w-full p-2.5"
                                  placeholder="$45 Pretty in Pink"
                                ></input>
                                <input
                                  id="instruction"
                                  name="instruction"
                                  class=" text-sm text-gray-900 bg-gray-50 my-2 rounded-sm border border-gray-300 block w-full p-2.5"
                                  placeholder="$75 Amber Florals"
                                ></input>
                                <input
                                  id="instruction"
                                  name="instruction"
                                  class=" text-sm text-gray-900 bg-gray-50 my-2 rounded-sm border border-gray-300 block w-full p-2.5"
                                  placeholder="$80 Turqouise and Coral Hearts"
                                ></input>
                                
                              </div>
                              <div class="col-span-2">
                                <label
                                  for="total"
                                  class="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Total
                                </label>
                                <input
                                  id="total"
                                  name="total"
                                  class=" text-sm text-gray-900 bg-gray-50 rounded-sm border border-gray-300 block w-full p-2.5"
                                  placeholder="$200"
                                ></input>
                              </div>
                              <div class="w-full">
                                <label
                                  for="databaseID"
                                  class="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Database ID
                                </label>
                                <input
                                  type="text"
                                  name="databaseID"
                                  id="databaseID"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm block w-full p-2.5"
                                  placeholder="ckAlqHqc4PhdKdJB39mbYXds4nH2"
                                />
                              </div>
                              <div class="w-full">
                                <label
                                  for="tracking"
                                  class="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                  Tracking Number
                                </label>
                                <input
                                  type="text"
                                  name="tracking"
                                  id="tracking"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm block w-full p-2.5"
                                  placeholder="#112500214089792227"
                                />
                              </div>

                        
                            </div>
                          </form>


                      </Collapsible>
                    </li>
                  </ul>
                </div>
              </div>



            {/** SUBSCRIBERS 

            <div className="col-span-1 bg-[#e7dee2] py-2 md:order-1 ">
              <h1 className="text-gray-600">Subscribers</h1>
              {/** NUMBER OF SUBSCRIBERS FROM DATABASE 
              <span className="inline-block">
                <h1 className="rounded-full bg-[#c9c1c5] w-20 h-20 py-3 mx-auto inline-block align-middle">
                  19
                </h1>{" "}
              </span>
              {/** LIST OF EMAILS FROM DATABASE 
              <h5>Mailing List</h5>
                <ul className="overflow-hidden text-left">
                  <li>DimitriNavrides@gmail.com</li>
                  <li>carolt@futuregraphics.com</li>
                  <li>mllamkin@mac.com</li>
                  <li>monse_jaramillo30@yahoo.com</li>
                  <li>rkitlitz@gmail.com</li>
                  <li>kaitlynnchavez98@gmail.com</li>
                  <li>kamillehoggan@gmail.com</li>
                  <li>henrymarin1099@gmail.com</li>
                  <li>heller.morgan415@gmail.com</li>
                  <li>gbarba98@gmail.com</li>
                  <li>desirae.wms@gmail.com</li>
                  <li>daisieflower85@yahoo.com</li>
                  <li>daaz@rocketmail.com</li>
                  <li>brainyblonde@live.com</li>
                  <li>aicheney@yahoo.com</li>
                  <li>charliemizer97@gmail.com</li>
                  <li>katherinehw@icloud.com</li>
                  <li>xokkatxo@gmail.com</li>

                </ul>
              {/**  BUTTON TO COPY LIST  
            </div>



            {/** EARNINGS 

            <div className="col-span-1 bg-[#e7dee2] py-2 text-center md:order-3">
              <h1 className="text-gray-600">Earnings</h1>

              <hr className="w-full mx-auto"></hr>

              <span className="flex">

                <div className="mx-auto">$
                  <h3 className="rounded-full bg-[#c9c1c5] w-20 h-20 py-4 mx-auto inline-block align-middle">
                  290.61
                  </h3>
                  <h5>net earnings</h5>
                </div>

                <div className="mx-auto">$
                  <h3 className="rounded-full bg-[#c9c1c5] w-20 h-20 py-4 mx-auto text-center inline-block align-middle">
                    299.90
                  </h3>
                  <h5>earnings </h5>
                </div>

                <div className="mx-auto">$
                  <h3 className="rounded-full bg-[#c9c1c5] w-20 h-20 py-4 mx-auto text-center inline-block align-middle">
                    -9.29
                  </h3>
                  <h5>stripe fees </h5>
                </div>
              </span>
              <hr className="w-full mx-auto px-0"></hr>
              <h4>Earnings history:</h4>   {/* TABLE 
              <table class="table-auto border-collapse mx-auto">
                <thead>
                  <tr>
                    <th className="border-1 border-black px-6 md:px-2">#</th>
                    <th className="border border-black px-6 md:px-3">Total</th>
                    <th className="border border-black px-6 md:px-3">Stripe</th>
                    <th className="border border-black px-6 md:px-3">Net</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-[#fff8f8]">
                    <td>1</td>
                    <td>$94.95</td>
                    <td>$3.05</td>
                    <td>$91.90</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>$204.95</td>
                    <td>$6.24</td>
                    <td>$198.71</td>
                  </tr>
                </tbody>
                </table>
             
  </div> */}





        </div>
    </div>
  );
};

export default EllissaAdmin;
