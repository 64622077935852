import React from 'react';
import { Link } from 'react-router-dom';
import {
  emptyPurpleSunSquare,
  emptyPurpleSunBanner,
  purplelearnmorebtn

} from '../photos/notBeadwork';
import "./Subscribe.css";
import WhereToFindMe from '../pages/WhereToFindMe';

const UpcomingEvent = () => {
  return (
    <div>
        
        <div className='subscribe update bg-[#dccada] lg:py-6 md:py-12 text-[#664A2C] '>
            <div className="heart py-6 md:py-0 text-center">
                <img className='update-hearttemps mx-auto' src={emptyPurpleSunSquare} alt='Subscribe! Chickasaw made, Bay raised'/>
                <img className='update-hearttemps2 ml-auto ' src={emptyPurpleSunBanner} alt='Subscribe! Chickasaw made, Bay raised'/>
                <div className='centered-text  update-centered-text '>
                  <div className='mx-auto update-text'>
                    <h1 className='pb-[3vw] lg:pb-0'>UPCOMING EVENTS </h1>
                    
                      <span className='text-2xl'>Soil and Spirit Botanica Workshop </span><br></br>
                      <p className='text-md lg:text-sm'>@ ATMOS 8/3 2:30-7:00PM </p><br></br>
                      <span className=' text-2xl'> Sunday Streets </span><br></br>
                      <p className=' text-md lg:text-sm'>@ Valencia St. 8/24 11:00AM-4:00PM </p>
                   
                    
                  </div>
                  <Link to="/WhereToFindMe">
                    <img
                      src={purplelearnmorebtn}
                      className="md:w-[8rem] wtfm-btn w-[6rem] mx-auto sm:mt-[2vw] lg:mt-[2vw]"
                      href="/AboutTheArtist"
                      alt="Native American Women Owned Business"
                    />
                  </Link>
                </div>

            </div>
        </div>
    </div>
  )
}

export default UpcomingEvent;