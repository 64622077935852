import { Swiper, SwiperSlide } from "swiper/react";
import { ProductsData } from "../api/Api";
import ProductsCard from './ProductsCard';
import 'swiper/css';
import 'swiper/css/free-mode';
import './products.css';
import './productswiper.css';


import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {  Navigation, Pagination } from 'swiper/modules';

const ProductSwiper = ({userID}) => {
  const { products, loading } = ProductsData();

  if (loading) {
    return <h3 className="text-center py-6 text-[#694444] blackest">Loading...</h3>;
  }

  return (
    <div>
      <div className='w-full relative  '>
        <Swiper
          freeMode={true}
          grabCursor={true}
          navigation={true}


        modules={[Navigation]}
        className="mySwiper insta-sq m-0 lg:px-32 "
        style={{'--swiper-navigation-size': '20px'}}
          breakpoints={{
            0: { slidesPerView: 1, spaceBetween: 10 },
            480: { slidesPerView: 2, spaceBetween: 10 },
            640: { slidesPerView: 2.25, spaceBetween: 10 },
            760: { slidesPerView: 3, spaceBetween: 10 },
            1024: { slidesPerView: 4.1, spaceBetween: 10 },
            1200: { slidesPerView: 4.1, spaceBetween: 5 }
          }}
        >
          {products.map((item) => (
            <SwiperSlide key={item.metadata._id}>
              <ProductsCard product={item} userID={userID}/>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ProductSwiper;