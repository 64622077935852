/*import React from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteItem, resetCart } from '../redux/katSlice';
import { ToastContainer, toast } from 'react-toastify';
import "../pages/cart.css";


{/**  const shippingAmt = (totalAmt*.01).toFixed(2);
  const salesTax = ((totalAmt*0.029)+0.3).toFixed(2);
  const finalAmt = totalAmt+shippingAmt+salesTax; }


  const CartItem = () => {
  const dispatch = useDispatch();
  const productData = useSelector((state) => state.kat.productData);



  return (
   
  
      <div className='my-4'>

          
           
          
        {productData.map((item) => (

            <div key={item._id} className=' text-[#545354]'>

              <div className='flex items-center my-2 cols-5 gap-1'>

                <MdOutlineClose 
                  onClick={()=>dispatch(deleteItem(item._id))&toast.error} 
                  className='text-xs md:text-sm hover:text-red-100
                  cursor-pointer duration-300' 
                />

                <img className='cartimage w-full' src={item.image} alt="product"/>

                
                  <h5 className='w-full pl-3'>{item.title}</h5>
                  <h5 className=' text-lg text-right col-span-3'>${item.price}</h5>
                  
            
                

              </div> 
          </div>
          ))}
          
      


      <button 
        onClick={() => 
          dispatch(resetCart())
        }
        className='bg-[#dccada] font-serif text-[#545354] text-sm mt-8 rounded-sm py-1 px-4  duration-300'>
          clear cart
      </button>

      </div>


      


  );
};

export default CartItem;*/

import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { db, auth } from "../firebase"; // Assuming db is your initialized Firestore instance
import { MdOutlineClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function Cart({ setFinalAmt, setTotalAmt, setMetal }) {
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        // Replace 'user123' with the actual user ID
        const currentUser = auth.currentUser;
        if (!currentUser) {
          // User not authenticated, handle accordingly
          return;
        }
        const currentUserId = currentUser.uid;
        console.log("id: ", typeof currentUserId);

        // Query the Firestore collection for the user's document based on their userID
        const userQuery = query(
          collection(db, "Users"),
          where("userId", "==", currentUserId)
        );
        const userSnapshot = await getDocs(userQuery);

        // Check if user exists
        if (!userSnapshot.empty) {
          // Get the user document
          const userDoc = userSnapshot.docs[0];

          // Query the "cart items" subcollection for the user
          const cartItemsQuery = query(collection(userDoc.ref, "cart items"));
          const cartItemsSnapshot = await getDocs(cartItemsQuery);

          // Extract cart items from the snapshot
          const items = cartItemsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCartItems(items);
        } else {
          console.log("User not found");
        }
      } catch (error) {
        console.error("Error fetching user cart items:", error);
      }
    };

    fetchCartItems();
  }, []);

  const handleRemoveFromCart = async (item) => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        return;
      }
      const currentUserId = currentUser.uid;

      const userQuery = query(
        collection(db, "Users"),
        where("userId", "==", currentUserId)
      );
      const userSnapshot = await getDocs(userQuery);

      if (!userSnapshot.empty) {
        const userDoc = userSnapshot.docs[0];
        const cartItemsCollection = collection(userDoc.ref, "cart items");

        console.log("item cartitem: ", item);

        const existingCartItemQuery = query(
          cartItemsCollection,
          where("id", "==", item.id)
        );
        const existingCartItemSnapshot = await getDocs(existingCartItemQuery);

        if (!existingCartItemSnapshot.empty) {
          existingCartItemSnapshot.forEach(async (doc) => {
            const { metal, price } = doc.data(); // Get metal field from Firestore document
            const priceToAdd = metal === true ? 5.0 : 0; // Check if metal is true
            await deleteDoc(doc.ref);
            console.log("Document successfully deleted");

            setCartItems((prevCartItems) =>
              prevCartItems.filter((cartItem) => cartItem.id !== item.id)
            );

            // Update total amount based on metal field of the removed item
            setTotalAmt(
              (prevTotalAmt) => prevTotalAmt - parseFloat(price) - priceToAdd
            );
            const newTotalAmount = cartItems
              .filter((cartItem) => cartItem.id !== item.id)
              .reduce(
                (total, cartItem) => total + parseFloat(cartItem.price),
                0
              );

              if(priceToAdd > 0){

                setMetal(false);

              }

            // Update the total amount state
            setTotalAmt(newTotalAmount);
            setFinalAmt(newTotalAmount);
            // Show a success message
            {/*toast.success("Item removed from cart");**/}
          });
        }
      }
    } catch (error) {
      console.error("Error removing item from cart:", error);
    }
  };

  return (
    <div className="my-4">
      {cartItems.map((item) => (
        <div key={item.id} className="text-[#545354]">
          <div className="flex items-center my-2 cols-6 gap-1">
            <MdOutlineClose
              onClick={() => handleRemoveFromCart(item)}
              className="w-[8vw] md:w-[2vw] hover:text-red-100 cursor-pointer duration-300"
            />

            <img className="cartimage w-full" src={item.image} alt="product" />
            <h5 className="w-full pl-3">{item.title}</h5>
            <h5 className="text-lg text-right col-span-3">${item.price}</h5>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Cart;
