import React from 'react';
import { FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Subscribe.css';
import { useState, useEffect, useRef } from 'react';
import EllissaAdmin from '../pages/EllissaAdmin';



const Footer = () => {
    const timerId = useRef(null);
    const[toggle, setToggle] = useState(false);

    useEffect(() => {

        if(toggle) {

            timerId.current = setTimeout(() => {

                setToggle(false);

            }, 5000);

        }

        return () => {

            clearTimeout(timerId.current);

        }

    }, [toggle]);


    function toggleEmail() {
        setToggle(true);

    };






  return (
    <div className="bg-[#dccada] text-center quattrocento ">

        <div className=" text-[#6e5a67] flex flex-row items-center text-center quattrocento h-[12rem] ">
        
                <ul className="flex flex-wrap mx-auto max-w-[630px] px-3 pt-4 justify-center gap-4 md:gap-5 lg:gap-5 list-none ">
                    <li>
                        <Link to="https://www.instagram.com/ihootaloowa/" className=" text-xl  text-[#755e5e] hover:text-[#51342c]  cursor-pointer"  >
                            <FaInstagram />
                        </Link> 
                    </li>
                    <li>
                        <Link to="/PrivacyPolicy" className='  text-[#755e5e] text-sm md:text-base lg:text-base hover:text-[#51342c] duration-300 cursor-pointer no-underline hover:underline'>
                            privacy policy
                        </Link>
                    </li>
                    <li>
                        <Link to="/TermsandCond" className=' text-[#755e5e] text-sm md:text-base lg:text-base hover:text-[#51342c] duration-300 cursor-pointer no-underline hover:underline'>
                            terms and conditions
                        </Link>
                    </li>
                    <li>
                        <Link to="/FAQ" className=' text-[#755e5e] text-sm md:text-base lg:text-base hover:text-[#51342c] duration-300 cursor-pointer no-underline hover:underline'>
                            faq
                        </Link>
                    </li>
                    <li>
                            <button onClick={toggleEmail} className=' text-[#755e5e] text-sm md:text-base lg:text-base hover:text-[#51342c] duration-300 cursor-pointer no-underline hover:underline'>
                                contact us
                            </button>
                            </li>
                    <li>
                            {toggle && (
                                    <span className=' text-[#755e5e] text-sm md:text-base  duration-300'>
                                        contactihootaloowa@gmail.com
                                    </span>
                                    
                                )}
                            
                    </li>
                    
                    
                </ul>
                
                
                
        </div>
        

        <Link to="/" className='text-center justify-center no-underline mx-auto mb-0'>
            <span className="text-[#f2e5e5] mx-auto text-xs mb-0"> © Ihoo Taloowa 2024 </span>
        </Link>
        
    </div>
  )
}

export default Footer;