import React, { useEffect, useState, useRef } from "react";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "./Subscribe.css";
import { useLocation } from "react-router-dom";
import { ProductsData } from "../api/Api";
import {
  addDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db, auth } from "../firebase";

const Product = () => {
  const [baseQty] = useState(1);
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const timerId = useRef(null);
  const { products, loading: productsLoading } = ProductsData();
  const location = useLocation();
  const [product, setProduct] = useState({});
  const [selectedMetal, setMetal] = useState(false);
  useEffect(() => {
    setProduct(location.state.item);
  });

  useEffect(() => {
    if (toggle) {
      timerId.current = setTimeout(() => {
        setToggle(false);
      }, 2000);
    }
    return () => {
      clearTimeout(timerId.current);
    };
  }, [toggle]);

  const handleAddToCart = async () => {
    setLoading(true);

    try {
      // Check if products are still loading
      if (productsLoading) {
        console.log("Products are still loading...");
        return;
      }
      // Check if products array is populated and not empty
      if (products && products.length > 0) {
        // Accessing the first product assuming there's only one

        const currentUser = auth.currentUser;
        if (!currentUser) {
          // User not authenticated, handle accordingly
          return;
        }
        const currentUserId = currentUser.uid;

        // Check if the current user exists in the Users collection
        const userQuery = query(
          collection(db, "Users"),
          where("userId", "==", currentUserId)
        );

        const userSnapshot = await getDocs(userQuery);

        const data = {
          id: product.metadata._id,
          title: product.name,
          image: product.metadata.image,
          price: product.metadata.price,
          quantity: baseQty,
          description: product.description,
          priceId: product.default_price,
          sold: product.metadata.sold,
          metal: selectedMetal === "$5 Hypoallergenic hook" ? true : false,
        };

        if (userSnapshot.empty) {
          // If user doesn't exist in Users collection, add them
          const newUserDocRef = await addDoc(collection(db, "Users"), {
            userId: currentUserId,
          });
          console.log("New user added ID: ", newUserDocRef.id);

          await addDoc(collection(newUserDocRef, "cart items"), data);
          console.log("Data added to cart items");
        } else {
          // If user already exists, update their cart items
          const userDoc = userSnapshot.docs[0];
          const cartItemsCollection = collection(userDoc.ref, "cart items");

          // Check if the item already exists in the user's cart
          const existingCartItemQuery = query(
            cartItemsCollection,
            where("id", "==", product.metadata._id)
          );
          const existingCartItemSnapshot = await getDocs(existingCartItemQuery);

          if (existingCartItemSnapshot.empty) {
            // If the item doesn't exist, add it to the cart items
            await addDoc(cartItemsCollection, data);
            console.log("Data added for existing user");
          } else {
            // If the item already exists, update its quantity or handle accordingly
            // For now, let's just log a message

            toast("ITEM IS ALREADY IN THE CART", {
              position: "top-left",
              className: "bg-white text-[#7f5454] blackest",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            console.log("Item already exists in cart");
          }
        }
      } else {
        console.error("Products array is empty or undefined.");
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    } finally {
      setLoading(false);
    }
  };

  if (productsLoading) {
    return <h3 className="md:mt-28 mt-14 text-center py-8 md:py-6 text-[#694444] blackest">Loading...</h3>;
  }

  return (
    <div className="bg-white md:mt-28 mt-14 pb-8 pt-3 md:py-6">
      <div className=" px-4 mx-auto max-w-4xl font-serif ">
        <Link className="no-underline" to="/shop">
          <button className="py-2 flex items-center gap-1  text-[#ab9191] hover:text-[#d3b1b1] duration-300">
            <span>
              <HiOutlineArrowLeft />
            </span>
            Return to shopping
          </button>
        </Link>

        {products && !productsLoading && (
          <div className="grid gap-4 py-8 sm:grid-cols-2  items-center sm:gap-6">
            <div className="w-full mx-auto items-center">
              <img
                className="w-full sm:w-98 h-[auto] mx-auto object-cover"
                src={product.metadata.image}
                alt="product image"
              />
            </div>
            <div className="w-full blackest h-60 md:justify-center text-[#694444]">
              <h2>{product.name}</h2>
              <h2>${product.metadata.price}</h2>
              <p className="text-sm  mt-3">{product.description}</p>
              <div className="flex gap-4 text-center mx-auto items-center">
                <div className="w-1/2 flex items-center text-center justify-between text-[#694444] gap-4 py-2">
                  {product.metadata.metalChoice === "true" ? (
                    <div className="w-full text-center text-[#694444] py-2">
                      <select
                        className="no-border text-sm rounded-lg focus:border-[#7f5454] w-full p-2.5 dark:focus:border-white"
                        placeholder="Metal Material"
                        onChange={(e) => setMetal(e.target.value)}
                      >
                        <option value="" selected="selected">
                          Metal Material
                        </option>
                        <option value="$5 Hypoallergenic hook">
                          $5 Hypoallergenic hook
                        </option>
                      </select>
                    </div>
                  ) : (
                    <p className="text-[#694444]">
                      This Product does not have a metal choice.
                    </p>
                  )}
                </div>
                {loading ? (
                  <button className="border-2 border-[#7f5454] text-[#7f5454] py-2 px-2 w-1/2 hover:bg-[#f8f1f3]">
                    Added to Cart!
                  </button>
                ) : (


                  <button
                    onClick={handleAddToCart}
                    className={`border-2 border-[#7f5454] text-[#7f5454] py-2 px-2 w-1/2 hover:bg-[#f8f1f3] ${
                      product.metadata.sold === "true"
                        ? "cursor-not-allowed opacity-50"
                        : ""
                    }`}
                    disabled={product.metadata.sold === "true"}
                  >
                    {product.metadata.price === "-" &&
                      "Temporarily Unavailable"}
                    {product.metadata.price !== "-" &&
                      (product.metadata.sold === "true"
                        ? "SOLD OUT"
                        : "ADD TO CART")}
                  </button>

                  
                )}
              </div>
              <Link className="no-underline" to="/Cart">
                <button className="border-2 border-[#7f5454] text-[#7f5454] py-2 px-2 my-2 w-full hover:bg-[#f8f1f3]">
                  GO TO CART
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
      <ToastContainer
        position="top-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default Product;
