import { useEffect, useState } from 'react';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { addUser } from '../redux/katSlice'; // Import the addUser action

const Auth = () => {

  const [authInitialized, setAuthInitialized] = useState(false);
  const [userID, setUserID] = useState(null);

  useEffect(() => {
    const auth = getAuth();

    const signInAnonymouslyAndGetUserID = async () => {
      try {
        const { user } = await signInAnonymously(auth);
        setUserID(user.uid); 
      } catch (error) {
        console.error('Error signing in anonymously:', error);
      } finally {
        setAuthInitialized(true);
      }
    };

    signInAnonymouslyAndGetUserID();
  }, []);


  return authInitialized ? userID : null;
};

export default Auth;