import { useRef, useState } from 'react';
import React from 'react';
import { PiHandbagSimple, PiUserSquareFill } from 'react-icons/pi';
import { FaInstagram, FaHeart } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './Navbar.css';
import { HiBars3 } from "react-icons/hi2";
import { FaBars, FaTimes } from 'react-icons/fa';
import { ihootaloowaLogo } from "../assets/index";
import { newLogo } from '../photos/notBeadwork';






const Navbar = () => {
  const navRef = useRef();
  const productData = useSelector((state) => state.kat.productData);
  const userInfo = useSelector((state) => state.kat.userInfo);
  const[toggle, setToggle] = useState(false);
   

  function showNavbar () {
    navRef.current.classList.toggle("responsive_nav");
    setToggle(true);
  };

  /*{ need to hide Navbar when link is clicked}*/
  function hideNavbar () {
    navRef.current.classList.remove("responsive_nav");
    setToggle(false);
  };

  


      
      return (

        <div  width='100%' display='flex'>

              <header position="fixed" top="0" display='flex' flex-direction="column">
                  <Link to="/" alt="IHOO TALOOWA BEADS" class="logo ">
                      <img class="w-[68vw] sm:max-w-[400px] md:max-h-[76px] min-h-[58px] sm:max-h-[58px] mx-auto py-1 lg:py-0 " src={newLogo} alt="ihoo taloowa authentic chickasaw beaded jewelry handmade by Elissa Thompson"/>
                  </Link>

                   
           



                    <nav className='md:flex md:h-9 md:leading-9 md:flex-row md:items-center  md:justify-center md:mx-auto' ref={navRef}>
                

                        
                       
                          <div>
                            <Link to="/" className="navlink link-left navCen" onClick={hideNavbar}>
                              <span>Home</span>
                            </Link>
                        </div>
                        <div>
                            <Link to="/Shop" className="navlink link-left navCen" onClick={hideNavbar}>
                              <span>Shop</span>
                            </Link>
                        </div>
                        <div>
                            <Link to="/AboutTheArtist" className="navlink link-left navCen" onClick={hideNavbar}>
                              <span>About the Artist</span>
                            </Link>
                        </div>
                        <div>
                            <Link to="/WhereToFindMe" className="navlink link-left navCen" onClick={hideNavbar}>
                              <span>Where to Find Me</span>
                            </Link>
                        </div>
                        <div>
                            <Link to="/PastProjects" className="navlink link-left navCen" onClick={hideNavbar}>
                              <span>Past Projects</span>
                            </Link>
                        </div>
                        <div>
                            <Link to="/FAQ" className="navlink link-left navCen"  onClick={hideNavbar}>
                              <span>FAQ</span>
                            </Link>
                        </div>
                        <div className='invisicons text-right'>
                          <Link to="https://www.instagram.com/ihootaloowa/" className="navlink"  onClick={hideNavbar}>
                            <FaInstagram />
                          </Link>
                    
                          <Link to="/cart" className="relative navlink px-2 flex"  onClick={hideNavbar}>
                            <PiHandbagSimple /> 
                            {/*<span className="absolute top-3 right-0 z-50 text-xs text-[#574145] flex items-center justify-center">
                                        {productData.length}
      </span> */}

                          </Link>
                          
                            
                        </div>
                        

                    
                       
                        

                        
                


                      
                    

                      
                        
                    
                        {toggle && (
                          <span>
                            <button className=" nav-btn nav-close-btn" onClick={hideNavbar}>{/**toggle false */}
                              <FaTimes />
                            </button>
                            
                            
                          </span>
                          
                          


                        )}
                      


                    </nav>
                  
                    {!toggle && (
                      <span>
                        <button className="nav-btn nav-bars text-[2rem]" onClick={showNavbar}>
                          <HiBars3 />
                        </button>
                        <div className='invisicons2 pr-[1vw]'>
                          <Link to="https://www.instagram.com/ihootaloowa/" className="navlink"  onClick={hideNavbar}>
                            <FaInstagram />
                          </Link>
                    
                          <Link to="/cart" className="relative navlink px-2 flex"  onClick={hideNavbar}>
                            <PiHandbagSimple /> 
                            {/**<span className="absolute top-3 right-0.5 z-50 text-xs text-[#574145] flex items-center justify-center">
                                        {productData.length}
                    </span> */}

                        

                          </Link>
                          
                            
                        </div>
                    </span>
                    )}
                    

                    


              </header> 
           
          </div>  

        






        )}

export default Navbar;