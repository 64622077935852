import React from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Button } from 'react-bootstrap';
 


const CarouselCard = props => {
    let {imgSrc, price, title} = props.data;
  return (
    <div>
        <Card className="p-0 h-68 overflow-hidden shadow">
            <div className='overflow-hidden rounded p-0 bg-light'>
                <Card.Img variant="top" className="h-68" src={imgSrc} />
            </div>
            {/**<Card.Body className="text-center">
                <Card.Title className="card-title">{price}</Card.Title>
                <Card.Title>{title}</Card.Title>
            </Card.Body>
            <Button className='w-100 btn btn-light rounded-0'>Add to Cart</Button>
            */}
        </Card>
        
        
    </div>
  )
}

export default CarouselCard;