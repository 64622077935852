import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CartItem from "../components/CartItem";
import { toast, ToastContainer } from "react-toastify";
import StripeCheckout from "react-stripe-checkout";
import axios from "axios";
import { Link, Route, Routes } from "react-router-dom";
import { HiOutlineArrowLeft } from "react-icons/hi";
import "./cart.css";
import ProceedToCheckout from "../stripecheckout/ProceedToCheckout";
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import { loadStripe } from "@stripe/stripe-js";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Cart = () => {
  //const [totalAmt, setTotalAmt] = useState("");
  const [payNow, setPayNow] = useState(false);
  /**const shippingAmt = (totalAmt*.01).toFixed(2);**/
  const shippingAmt = 4.95; //change this back to 4.95
  //you cant charge sales tax without a sales tax license
  //const salesTax = ((totalAmt*0.029)+0.3).toFixed(2);
  //const finalAmt = (totalAmt * 1 + shippingAmt).toFixed(2);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [totalAmt, setTotalAmt] = useState(0.0);
  const [finalAmt, setFinalAmt] = useState(0.0);
  const encodedAmt = encodeURIComponent(finalAmt);

  const [toggle, setToggle] = useState(false);

  const [prodID, setItemID] = useState([]);

  const [shippingInfo, setShippingInfo] = useState(null);

  const [findMetal, setMetal] = useState(false);

  const [userID, setUserID] = useState("");

  /*const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [instruction, setInstruction] = useState("");*/

  const [empty, setEmpty] = useState("false");

  // State for form validation

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    instruction: "",
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Update form validation state whenever any form field changes
  /*console.log(
    formData.email,
    formData.name,
    formData.address,
    formData.city,
    formData.state,
    formData.zipcode,
    formData.instruction
  );
*/
  const addItem = (newItem) => {
    setItemID((prevItems) => [...prevItems, newItem]);
  };

  const handleSubmit = async () => {
    // Here, you can gather shipping information from the form fields
    // and set it to the state variable `shippingInfo`
    const currentUser = auth.currentUser;
    if (!currentUser) {
      // User not authenticated, handle accordingly
      return;
    }
    const currentUserId = currentUser.uid;
    console.log("id: ", typeof currentUserId);
    setUserID(currentUserId);

    // Query the Firestore collection for the user's document based on their userID
    const userQuery = query(
      collection(db, "Users"),
      where("userId", "==", currentUserId)
    );
    const userSnapshot = await getDocs(userQuery);

    const userDoc = userSnapshot.docs[0];

    const userRef = doc(db, "Users", userDoc.id);
    await updateDoc(userRef, {
      email: formData.email,
      name: formData.name,
      address: formData.address,
      city: formData.city,
      state: formData.state,
      zipcode: formData.zipcode,
      instruction: formData.instruction,
    });

  };

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        // Replace 'user123' with the actual user ID
        const currentUser = auth.currentUser;
        if (!currentUser) {
          // User not authenticated, handle accordingly
          return;
        }
        const currentUserId = currentUser.uid;
        console.log("id: ", typeof currentUserId);
        setUserID(currentUserId);

        // Query the Firestore collection for the user's document based on their userID
        const userQuery = query(
          collection(db, "Users"),
          where("userId", "==", currentUserId)
        );
        const userSnapshot = await getDocs(userQuery);

        // Check if user exists
        if (!userSnapshot.empty) {
          // Get the user document
          const userDoc = userSnapshot.docs[0];

          // Query the "cart items" subcollection for the user
          const cartItemsQuery = query(collection(userDoc.ref, "cart items"));
          const cartItemsSnapshot = await getDocs(cartItemsQuery);

          // Extract cart items from the snapshot
          const items = cartItemsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          let totalPrice = 0;
          for (const item of items) {
            totalPrice += parseFloat(item.price);
            addItem(item.id);

            if (item.metal === true) {
              totalPrice += 5;
              setMetal(true);
            }
          }
          setTotalAmt(totalPrice);
          setFinalAmt(totalPrice + shippingAmt);
        } else {
          console.log("User not found");
        }
      } catch (error) {
        console.error("Error fetching user cart items:", error);
      }
    };

    fetchCartItems();
  }, [shippingInfo]);

  return (
    <div className="md:mt-28  mt-14 pt-4 md:pt-0 md:flex bg-[#f7f1f6] font-serif text-[#545354]">
      <div className="md:w-1/2 md:order-2 px-4 max-w-[650px] mx-auto py-[4vw] lg:py-[2vw]">
        {" "}
        {/** CART SUMMARY PINK BACKGROUND */}
        <h2 className="text-gray-700   font-light">Shopping Cart</h2>
        <Link className="no-underline" to="/">
          <button className=" mb-4 md:mb-8 flex font-sans font-light text-sm items-center gap-1 text-gray-500 hover:text-black duration-300">
            <span>
              <HiOutlineArrowLeft />
            </span>
            return to shopping
          </button>
        </Link>
        {/** LIST OF ITEMS IN CART
         * if cart is empty display message ese display cart item
         */}
        <div className="mx-auto gap-2 my-6">
          <CartItem
            setFinalAmt={setFinalAmt}
            setTotalAmt={setTotalAmt}
            setMetal={setMetal}
          />
        </div>
        <div class="py-8 cartsummary text-sm  mx-auto max-w-2xl lg:py-16">
          {findMetal && (
            <p className="flex font-titleFont  justify-between font-semibold">
              Hypoallergenic hook <span className="text-lg ">$ 5</span>
            </p>
          )}
          <p className="flex font-titleFont  justify-between font-semibold">
            Subtotal <span className="text-lg "> $ {totalAmt.toFixed(2)}</span>
          </p>
          <p className="flex font-titleFont  justify-between font-semibold">
            Shipping Cost{" "}
            <span className=" text-lg ">$ {shippingAmt.toFixed(2)}</span>
          </p>
          {/*<p className='flex font-titleFont  justify-between font-semibold'>
              Sales Tax{" "}<span className='text-lg'> $ {salesTax}</span>
        </p>*/}
          <p className="text-xl font-bold flex border-t-[1px] border-[#888888] justify-between mt-6 pt-3">
            Total <span className="text-lg">$ {finalAmt.toFixed(2)}</span>
          </p>
        </div>
      </div>

      {/** SHIPPING INFORMATION FORM */}

      <div class=" text-[#545354] md:w-1/2 md:order-1 px-4 lg:px-8 py-[4vw] lg:py-[2vw] bg-white ">
        <form action="#">
          <div class="grid  grid-cols-2 gap-2 max-w-[600px] mx-auto">
            <h2 className="text-gray-700 font-light col-span-2 mb-4">
              Shipping
            </h2>
            {/** GET THIS EMAIL TO THE PAYMENT SCREEN SOMEHOW */}
            <div class="col-span-2 lg:w-full">
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 "
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm  block w-full p-2.5 "
                placeholder="email address"
                required
                value={formData.email}
                onChange={handleChange}
                autocomplete="Exactemail"
              />
            </div>

            <div class="col-span-2 lg:w-full">
              <label
                for="name"
                class="block mb-2 text-sm font-medium text-gray-900 "
              >
                Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm block w-full p-2.5"
                placeholder="full name"
                required
                value={formData.name}
                onChange={handleChange}
                autocomplete="fullName"
              />
            </div>
            <div class="col-span-2">
              <label
                for="address"
                class="block mb-2 text-sm font-medium text-gray-900 "
              >
                Address
              </label>
              <input
                type="text"
                name="address"
                id="address"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm block w-full p-2.5"
                placeholder="address"
                value={formData.address}
                onChange={handleChange}
                autocomplete="zipAddress"
                required
              />
            </div>
            <div class="w-full">
              <label
                for="city"
                class="block mb-2 text-sm font-medium text-gray-900 "
              >
                City
              </label>
              <input
                type="text"
                name="city"
                id="city"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm block w-full p-2.5"
                placeholder="city"
                required
                value={formData.city}
                onChange={handleChange}
                autocomplete="city"
              />
            </div>
            <div className="states">
              <label
                for="state"
                class="block mb-2 text-sm font-medium text-gray-900 "
              >
                State
              </label>
              <select
                id="state"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm  block w-full p-2.5  "
                name="state"
                value={formData.state}
                onChange={handleChange}
                autocomplete="state"
                required
              >
                <option value="" selected="selected">
                  Select a State
                </option>
                <option className="chooseState" value="AL">
                  Alabama
                </option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
            <div class="w-full">
              <label
                for="zipcode"
                class="block mb-2 text-sm font-medium text-gray-900 "
              >
                Zip Code
              </label>
              <input
                type="text"
                name="zipcode"
                id="zipcode"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm block w-full p-2.5"
                placeholder="Zip Code"
                required
                value={formData.zipcode}
                onChange={handleChange}
                autocomplete="zipcode"
              />
            </div>
            <div class="w-full">
              <label
                for="instruction"
                class="block mb-2 text-sm font-medium text-gray-900 "
              >
                Notes
              </label>
              <input
                id="instruction"
                name="instruction"
                class=" text-sm text-gray-900 bg-gray-50 rounded-sm border border-gray-300 block w-full p-2.5"
                placeholder="Notes"
                value={formData.instruction}
                onChange={handleChange}
              ></input>
            </div>

            {/** BUTTON PROCEEDS TO PAYMENT INFORMATION */}

            {/*<Link
              to={`/ProceedToCheckout?amount=${finalAmt}&prodID=${prodID}`}
              className="text-base col-span-2 text-center no-underline bg-[#dccada] text-[#545354] py-3 mt-6 rounded-sm duration-300"
              onClick={handleProceedToCheckout}
            >
              Proceed to Payment
              </Link>*/}

            {!formData.name ||
            !formData.email ||
            !formData.address ||
            !formData.zipcode ||
            !formData.state ? (
              <span className="text-base col-span-2 text-center no-underline bg-[#dccada] text-[#545354] py-3 mt-6 rounded-sm duration-300">
                Proceed to Checkout
              </span>
            ) : (
              <Link
                className="text-base col-span-2 text-center no-underline bg-[#dccada] text-[#545354] py-3 mt-6 rounded-sm duration-300"
                to={`/ProceedToCheckout?amount=${finalAmt}&prodID=${prodID}&userID=${userID}`}
                onClick={handleSubmit}
              >
                Proceed to Checkout
              </Link>
            )}
            <span className="text-center col-span-2 text-xs">
              Secure checkout with Stripe
            </span>
          </div>
        </form>
      </div>
      
    </div>
  );
};

export default Cart;