import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./productsCard.css";
import {
  addDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db, auth } from "../firebase";

import "bootstrap/dist/css/bootstrap.min.css";
import { Card } from "react-bootstrap";

const ProductsCard = ({ product }) => {
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const timerId = useRef(null);
  const navigate = useNavigate();
  const _id = product.name;
  const [baseQty] = useState(1);

  const handleDetails = () => {
    if (product) {
      navigate(`/product/${_id}`, {
        state: {
          item: product,
        },
      });
    }
  };

  const handleAddToCart = async () => {
    setToggle(true);
    try {
      // Get the current user's ID
      const currentUser = auth.currentUser;
      if (!currentUser) {
        // User not authenticated, handle accordingly
        return;
      }
      const currentUserId = currentUser.uid;

      // Check if the current user exists in the Users collection
      const userQuery = query(
        collection(db, "Users"),
        where("userId", "==", currentUserId)
      );
      const userSnapshot = await getDocs(userQuery);

      console.log("product data: ", product);

      const data = {
        id: product.metadata._id,
        title: product.name,
        image: product.metadata.image,
        price: product.metadata.price,
        quantity: baseQty,
        description: product.description,
        priceId: product.default_price,
      };

      if (userSnapshot.empty) {
        // If user doesn't exist in Users collection, add them
        const newUserDocRef = await addDoc(collection(db, "Users"), {
          userId: currentUserId,
        });
        console.log("New user added ID: ", newUserDocRef.id);

        await addDoc(collection(newUserDocRef, "cart items"), data);
        console.log("Data added to cart items");
      } else {
        // If user already exists, update their cart items
        const userDoc = userSnapshot.docs[0];
        const cartItemsCollection = collection(userDoc.ref, "cart items");

        // Check if the item already exists in the user's cart
        const existingCartItemQuery = query(
          cartItemsCollection,
          where("id", "==", product.metadata._id)
        );
        const existingCartItemSnapshot = await getDocs(existingCartItemQuery);

        if (existingCartItemSnapshot.empty) {
          // If the item doesn't exist, add it to the cart items
          await addDoc(cartItemsCollection, data);
          console.log("Data added for existing user");
        } else {
          // If the item already exists, update its quantity or handle accordingly
          // For now, let's just log a message

          toast("ITEM IS ALREADY IN THE CART", {
            position: "top-left",
            className: "itemAlready blackest",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          console.log("Item already exists in cart");
        }
      }
      setToggle(true);
    } catch (error) {
      console.error("Error adding item to cart:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (toggle) {
      timerId.current = setTimeout(() => {
        setToggle(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timerId.current);
    };
  }, [toggle]);

  return (
    <div className="hover:cursor-pointer">
      <Card className=" flex-col border-0 sm:h-[29rem] h-[32rem] mb-2">
        <div
          onClick={handleDetails}
          className="overflow-hidden p-0  rounded-none"
        >
          <Card.Img
            className="max-h-[88vw] w-[88vw]  md:max-h-[275px] md:max-w-[275px]"
            variant="top"
            src={product.metadata.image}
            alt="Chickasaw made jewelry"
          />
        </div>
        <Card.Body className="text-left bg-white  text-[#7f5454] px-0 md:max-w-[275px] blackest">
          <div className="grid grid-cols-1  text-[#7f5454]">
            <span onClick={handleDetails} className="pb-2">
              <div className="text-2xl min-h-[64px] max-h-[64px] overflow-hidden">
                {product.name && <>{product.name.substring(0, 33)}</>}
              </div>

              <div className="text-xl">${product.metadata.price}</div>
            </span>
            {/** METAL OPTIONS AVAILABLE? IF SO STATE HERE WITH SMALL TEXT*/}

            <div className="w-full">

              {toggle && (
                <button
                  className="mx-auto border-2  py-2 border-[#6e5a5a]  text-center w-full bg-[#f7ecef]"
                  alt="Native American Beadwork"
                >
                  ADDED TO CART!
                </button>
              )}

              {!toggle && (
                <button
                  onClick={handleAddToCart}
                  className={`border-2 border-[#7f5454] text-[#7f5454] py-2 w-full hover:bg-[#f8f1f3] ${
                    product.metadata.sold === "true"
                      ? "cursor-not-allowed opacity-50"
                      : ""
                  }`}
                  disabled={product.metadata.sold === "true"}
                >
                  {product.metadata.price === "-" && "Temporarily Unavailable"}
                  {product.metadata.price !== "-" &&
                    (product.metadata.sold === "true"
                      ? "SOLD OUT"
                      : "ADD TO CART")}
                </button>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>

    </div>
  );
};

export default ProductsCard;
