import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
import CarouselCard from './CarouselCard';

import 'swiper/css';
import 'swiper/css/free-mode';

import 'bootstrap/dist/css/bootstrap.min.css';

//import images
import { 
  insta1succulent, 
  insta2pinktable, 
  insta3assorted, 
  insta4rainbow, 
  insta5bluetable, 
  insta6purpflat
} from "../photos";



const Carousel = () => {
  return (
    <div className='container py-4 px-4 justify-content-center '>
        <Swiper
          FreeMode={true}
          grabCursor={true}
          modules={[FreeMode]}
          className="mySwiper"
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            760: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 15,
            },
            1200: {
              slidesPerView: 6,
              spaceBetween: 30,
            }
          }}


          /*
          slidesPerView={3}
          spaceBetween={30}
          */

        >
          <SwiperSlide>
            <CarouselCard data={{imgSrc: insta1succulent, price: '$40', title: 'Pink Dangling Hoops'}} />
          </SwiperSlide>
          <SwiperSlide>
            <CarouselCard data={{imgSrc: insta2pinktable, price: '$45', title: 'other things'}} /> 
          </SwiperSlide>
          <SwiperSlide>
            <CarouselCard data={{imgSrc: insta3assorted, price: '$130', title: 'lorem ispum'}} />
          </SwiperSlide>
          <SwiperSlide>
            <CarouselCard data={{imgSrc: insta4rainbow, price: '$50', title: 'different labels'}} />
          </SwiperSlide>
          <SwiperSlide>
            <CarouselCard data={{imgSrc: insta5bluetable, price: '$80', title: 'beads jewelry'}} />
          </SwiperSlide>
          <SwiperSlide>
            <CarouselCard data={{imgSrc: insta6purpflat, price: '$100', title: 'titles'}} /> 
          </SwiperSlide>




        </Swiper>
        
        

    </div>
  )
}

export default Carousel;