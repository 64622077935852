// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

/*const firebaseConfig = {
  apiKey: "AIzaSyDeL4JGRHnr7H1gOtCoigMW0VzaHL360-0",
  authDomain: "ihootaloowa-ckck.firebaseapp.com",
  projectId: "ihootaloowa-ckck",
  storageBucket: "ihootaloowa-ckck.appspot.com",
  messagingSenderId: "362152588186",
  appId: "1:362152588186:web:c3b7c852a760e2b4c18023",
  measurementId: "G-3NFDK2Y9F4"
};*/

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCnuOtRLKjv-YsHnuGyHkYUwRHYAjn6fFQ",
  authDomain: "ihootaloowa-website.firebaseapp.com",
  projectId: "ihootaloowa-website",
  storageBucket: "ihootaloowa-website.appspot.com",
  messagingSenderId: "296038781882",
  appId: "1:296038781882:web:7a74199e3ef5f00bd58383",
  measurementId: "G-LL6193ZFG5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app), functions = getFunctions(app), auth = getAuth(app);