import React from 'react';



const FAQ = () => {
  return (


      <div class="md:mt-28 md:py-12 mt-14 py-8 px-4 bg-[#f2eaeb]">
       
            <h1 className="  mx-auto text-center text-[#8d6464]  blackest lg:max-w-[45vw]" >FREQUENTLY ASKED QUESTIONS AND POLICIES</h1>
    
          <ul className='pl-0 py-4 text-[#484646] quattrocento max-w-6xl mx-auto'>
            
            
            <li className="pb-4 md:h-50" >
              <h3 className='text-[#8d6464]'>How does pricing work?</h3>
              <p>Pricing is based on time, materials, and three years of beading experience.
                 Our current rate is $45/hr + $5 for shipping in the US only. 
                For my BIPOC & QT2S relatives, I offer a 20% discount.
                </p>
            </li>
            
            <li  className="py-4 md:h-50">
              <h3 className='text-[#8d6464]'>Do you offer repairs?</h3>
              <p>We pride ourselves in making quality beadwork, and we hope that with proper care there will be no need for you to check this disclaimer.
                If you do find yourself here, we apologize for the inconvenience. Repairs can be requested, however please keep in mind that some supplies
                 may not be available. First repairs are free but you are responsible for cost of shipping back to us. Any further repairs are a flat fee of $30.00.
                 We will do our best to accommodate your repair request. To submit a request for a repair please email
                contactihootaloowa@gmail.com
              </p>
                          
            </li>
            <li className="py-4  md:h-50">
              <h3 className='text-[#8d6464]'>Do you offer custom orders?</h3>
              <p>
                We do open up for custom order requests periodically. At the moment we are not taking any requests, however we will open
                up for customs in the future! Create an account if you'd like to recieve an email notification when customs are
                open. Customs are offered at artists discretion. We ask for full payment up front or 50% on orders $100+. Customs will be completed 
                within a maximum of 4 weeks.
              </p>
            </li>
            <li className="py-4  md:h-50">
              <div className=''>
              <h3 className='text-[#8d6464]'>Can anyone wear beadwork?</h3>
              <p>Yes! Although our beadwork is made with traditional values, it is not regalia and can be worn by anyone!
                Buying from Native Artists uplifts our community, and wearing our beadwork is not cultural appropriation. 
                If a piece is intended for Native relatives it will be specified in the item's description.
                </p>
              </div>
            </li>
            <li className="py-4  md:h-50">
              <div className=''>
                <h3 className='text-[#8d6464]'>Can I learn how to bead?</h3>
                <p>The styles of beadwork featured on Ihoo Taloowa are culturally specific and are not to be practiced by 
                  individuals who are not affiliated or legitimately connected to these cultures. Our artwork carries deep 
                  meaning specific to our lived experience as Indigenous people.
                 
                </p>
              </div>
            </li>
            <li  className="py-4  md:h-50">
                <h3 className='text-[#8d6464]'>Return and shipping policy</h3>
                <p>Thank you for choosing Ihoo Taloowa Beads! We are grateful for
                   the opportunity to provide you with quality beadwork. Once your 
                   order is mailed and you have received a tracking number, we are no 
                   longer responsible for the item(s). Returns and exchanges are not accepted.
                </p>
            </li>

          </ul>

      </div>



  )
}

export default FAQ;